import { postSubmit, messageSubmit } from './request'

const getQRcode = postSubmit(`/outsourcePersonnelEntranceApply/getQRcode`, 'formdata')
const getEquipagePhotograph = postSubmit(`/outsourcePersonnelEntranceApply/getEquipagePhotograph`, 'formdata')
const selectOutsourcePersonnelPage = postSubmit(`/outsourcePersonnelEntranceApply/selectOutsourcePersonnelPage`, 'formdata')
const selectCarryEquipageByOutsourcePersonnelId = postSubmit(`/outsourcePersonnelEntranceApply/selectCarryEquipageByOutsourcePersonnelId`, 'formdata')
const getAlarmRecordValue = messageSubmit(`/alarmRecord/getAlarmRecordValue`, 'formdata')
export {
    // 微信获取二维码
    getQRcode,
    // 获取外协人员携带装备照片
    getEquipagePhotograph,
    // 获取报警处理和查看数据
    getAlarmRecordValue,
    // 获取外协人员的申请记录列表
    selectOutsourcePersonnelPage,
    // 根据外协人员入院申请ID获取携带装备详细信息详细信息
    selectCarryEquipageByOutsourcePersonnelId
}