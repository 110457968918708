<template>
  <div class="content_box">
    <ul>
      <li>
        <em>报警类型</em
        ><span>{{ warningData.alarmRecordTypeEntity.alarmTypeName }}</span>
      </li>
      <li>
        <em>报警位置</em
        ><span>{{ warningData.alarmRecordEntity.devicePosition }}</span>
      </li>
      <li>
        <em>报警设备</em
        ><span>{{ warningData.alarmRecordEntity.deviceName }}</span>
      </li>
      <li>
        <em>报警时间</em
        ><span>{{ warningData.alarmRecordEntity.alarmTime }}</span>
      </li>
      <li>
        <em>报警级别</em
        ><span>{{ warningData.alarmRecordEntity.alarmLevel }}</span>
      </li>
      <!-- <li>
        <em>报警地图</em>
      </li> -->
      <!-- <iframe
        ref="iframeDom"
        src="http://192.168.3.127:59914/"
        frameborder="0"
        scrolling="no"
        width="100%"
        height="100%"
      ></iframe> -->
      
      <!-- <van-button
        type="primary"
        color="#38C7C4"
        @click="sendWebglMessage"
        class="btn"
        >确定</van-button
      > -->
    </ul>
  </div>
</template>
<script>
import { getAlarmRecordValue } from "@api/index.js";
export default {
  data() {
    return {
      warningData: {
        alarmRecordTypeEntity: {
          alarmTypeName: "",
        },
        alarmRecordEntity: {
          devicePosition: "",
          deviceName: "",
          alarmTime: "",
          alarmLevel: "",
          capturedPicture: "",
        },
      },
    };
  },
  mounted() {
    this.getAlarmRecordValue();
  },
  methods: {
    // 根据ID获取报警处理和查看数据
    getAlarmRecordValue() {
      let id = this.$route.query.id;
      getAlarmRecordValue({ id: id }).then((res) => {
        if (res.code == 200) {
          this.warningData = res.data;
        }
      });
    },
    sendWebglMessage() {
      console.log(123);
      // this.$refs.iframeDom.message("GameObject", "ViewCard", "7");
    },
  },
};
</script>
<style lang="scss" scoped>
.content_box {
  ul {
    height: 100%;
    overflow: auto;
  }
  li {
    display: flex;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #353535;
    padding: 10px 20px;
    em {
      font-style: normal;
    }
    span {
      color: #888;
      width: 260px;
      margin-left: 15px;
      font-size: 14px;
      margin-top: 2px;
    }
  }
  .btn {
    width: 90%;
    margin: 0 auto;
    display: block;
    line-height: 30px;
    font-size: 15px;
    margin-top: 10px;
  }
}
</style>