<template>
  <div class="not_pass">
    <img src="../../assets/img/notpass.png" class="not_p_img" alt="" />
    <h1>审核未通过</h1>
    <h6>入院申请还未通过，请联系申请部门负责人</h6>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.not_pass {
  padding-top: 70px;
  .not_p_img {
    width: 68px;
    height: 68px;
    position: relative;
    // top: 70px;
    left: 50%;
    transform: translateX(-50%);
  }
  h1 {
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #353535;
    padding-top: 20px;
    padding-bottom: 62px;
  }
  h6 {
    font-size: 14px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #353535;
    text-align: center;
  }
}
</style>