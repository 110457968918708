<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
// import { getSignature } from "./components/api/index";
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  mounted() {
    // this.getSignature();
  },
  created() {},
  methods: {
    pageJumps(index, path) {
      // this.footerIndex = index;
      // sessionStorage.setItem("footerIndex", this.footerIndex);
      // this.$router.push(path);
    },
    //注册微信api
    // getSignature() {
    //   getSignature({
    //     localUrl: location.href.split("#")[0],
    //     appId: __PATH.APPID,
    //   }).then((res) => {
    //     // console.log(res);
    //     sessionStorage.setItem("appId", res.data.appId);
    //     sessionStorage.setItem("accessToken", res.data.accessToken);
    //     wx.config({
    //       debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    //       appId: res.data.appId, // 必填，公众号的唯一标识
    //       timestamp: res.data.timestamp, // 必填，生成签名的时间戳
    //       nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
    //       signature: res.data.signature, // 必填，签名
    //       jsApiList: [
    //         // 拍照或从手机相册中选图接口
    //         "chooseImage",
    //         // 预览图片接口
    //         "previewImage",
    //         // 上传图片接口
    //         "uploadImage",
    //         // 获取本地图片接口
    //         "getLocalImgData",
    //         // 开始录音接口
    //         "startRecord",
    //         // 停止录音接口
    //         "stopRecord",
    //         // 播放语音接口
    //         "playVoice",
    //         // 暂停播放接口
    //         "pauseVoice",
    //         // 上传语音接口
    //         "uploadVoice",
    //         // 监听语音播放完毕接口
    //         "onVoicePlayEnd",
    //       ], // 必填，需要使用的JS接口列表
    //     });
    //     wx.error(function (err) {
    //       // 注册失败
    //       // console.log("注册失败");
    //       // this.$toast.fail('请返回上一层')
    //       // alert(JSON.stringify(err));
    //     });
    //   });
    // },
  },
};
</script>
<style lang="scss">
@import url("./assets/icon-font/iconfont.css");
html,
body,
#app {
  width: 100%;
  height: 100%;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // -webkit-touch-callout: none;
  // -webkit-user-select: none;
  // -khtml-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
}
* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input,
textarea {
  -webkit-user-select: auto; /*webkit浏览器*/
}

li {
  list-style: none;
}
.view_box {
  height: calc(100% - 48px);
  overflow: auto;
}
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 48px;
  background: #ffffff;
  box-shadow: 0px -1px 0px 0px #e6eaf0;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #cacad2;
  }
  .fot_name {
    margin-top: 2px;
    font-size: 10px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 14px;
  }
  .set_font_color {
    color: #29bebc;
  }
}
.apostrophe {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
// *:not(input,textarea) {
//    -webkit-touch-callout: none;
//    -webkit-user-select: none;
// }
</style>
