<template>
  <div class="pass_audit">
    <div class="get_through">
      <img src="../../assets/img/pass_bgi.png" alt="" />
      审核通过
    </div>
    <img :src="imgUrl" class="qr_code" alt="" />

    <p class="explain">请使用二维码扫码通过，如二维码失效请点击刷新按钮。</p>
    <div class="refresh" @click="getCode">
      <img src="../../assets/img/shuaxin.png" alt="" />
      刷新
    </div>

    <van-list
      :loading="loading"
      @load="selectOutsourcePersonnel"
      :finished="finished"
      finished-text="没有更多了"
    >
      <template v-for="(item, index) in staffDataList">
        <div class="equip_box" :key="index">
          <ul>
            <li>
              <span>到访部门</span>
              <p>{{ item.visitDepartmentName }}</p>
            </li>
            <li>
              <span>所属公司</span>
              <p>{{ item.affiliationCompany }}</p>
            </li>
            <li>
              <span>携带装备</span>
              <p
                @click="getImg(item.outsourcePersonnelEntranceApplyId)"
                class="reminder"
              >
                点击查看
              </p>
            </li>
            <li>
              <span>进出时间</span>
              <p>
                {{ item.planAccessTimeStart }} -- {{ item.planAccessTimeEnd }}
              </p>
            </li>
            <li>
              <span>备注</span>
              <p>{{ item.remark || "暂无数据" }}</p>
            </li>
          </ul>
        </div>
      </template>
    </van-list>
    <!-- <div class="close_page"></div> -->
    <img
      class="close_page"
      @click="logOut"
      src="../../assets/img/footer.png"
      alt=""
    />
  </div>
</template>
<script>
import {
  getQRcode,
  selectOutsourcePersonnelPage,
  selectCarryEquipageByOutsourcePersonnelId,
} from "@api/index.js";
import { ImagePreview } from "vant";
export default {
  data() {
    return {
      imgUrl: "",
      LOGINDATAWX: {},
      staffDataList: [],
      loading: false,
      finished: false,
      currentPage: 1,
    };
  },
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  // beforeRouteEnter(to, from, next) {
  //   if (localStorage.getItem("LOGINDATAWX")) {
  //     next();
  //   } else {
  //     next("/login");
  //   }
  // },
  filters: {},
  mounted() {
    this.imgUrl = this.$route.query.qrCode;
    this.LOGINDATAWX = JSON.parse(localStorage.getItem("LOGINDATAWX"));
    console.log(this.LOGINDATAWX);
    // this.selectOutsourcePersonnel();
    if (!this.LOGINDATAWX?.idNumber) {
      this.logOut();
      return;
    } else {
      setInterval(() => {
        getQRcode(this.LOGINDATAWX).then((res) => {
          if (res.code == 200) {
            this.imgUrl = res.data;
          } else {
            this.$toast.fail(res.msg);
          }
        });
      }, 3000);
    }
  },
  methods: {
    logOut() {
      localStorage.clear();
      this.$router.push({
        path: "/login",
      });
    },
    getImg(id) {
      const toast = this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      selectCarryEquipageByOutsourcePersonnelId({
        outsourcePersonnelEntranceApplyId: id,
      }).then((res) => {
        console.log(res);
        toast.clear();
        let imgArray = [];
        if (res.code == 200) {
          res.data.map((item) => {
            imgArray = imgArray.concat(item.equipagePhotographOuterNetList);
          });
          console.log(imgArray);
          if (imgArray.length) {
            ImagePreview({
              images: imgArray,
              beforeClose: () => false,
            });
          } else {
            this.$toast.fail("暂无携带装备图片");
          }
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    selectOutsourcePersonnel() {
      this.loading = true;
      selectOutsourcePersonnelPage({
        currentPage: this.currentPage,
        pageSize: 15,
        idNumber: this.LOGINDATAWX.idNumber,
        phone: this.LOGINDATAWX.phone,
      })
        .then((res) => {
          if (res.code == 200) {
            this.staffDataList = this.staffDataList.concat(res.data.records);
            if (!res.data.records.length) {
              this.finished = true;
            } else {
              this.currentPage++;
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.finished = true;
          this.$toast.fail("加载失败,请稍后再试");
        });
    },
    getCode() {
      const toast = this.$toast.loading({
        message: "刷新中...",
        forbidClick: true,
        duration: 0,
      });
      getQRcode(this.LOGINDATAWX).then((res) => {
        if (res.code == 200) {
          this.imgUrl = res.data;
        } else {
          this.$toast.fail(res.msg);
        }
        toast.clear();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.pass_audit {
  padding-top: 23px;
  padding-bottom: 50px;
  .get_through {
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #353535;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 26px;
    img {
      width: 24px;
      height: 24px;
      margin-right: 11px;
    }
  }
  .qr_code {
    width: 158px;
    height: 158px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  .period_validity {
    margin-top: 13px;
    display: flex;
    padding: 0 37px 0 48px;
    span {
      font-size: 14px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #8f8f8f;
      line-height: 20px;
    }
    .period_label {
      display: inline-block;
      width: 80px;
    }
  }
  .explain {
    font-size: 15px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #353535;
    line-height: 21px;
    padding: 22px 37px 0 38px;
  }
  .refresh {
    font-size: 14px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #29bebc;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    img {
      width: 14px;
      height: 14px;
      margin-right: 8px;
    }
  }

  .equip_box::before {
    content: "";
    display: inline-block;
    width: 100%;
    height: 10px;
    background-color: #f4f5f9;
    margin-top: 23px;
  }
  .equip_box {
    padding-bottom: 20px;
    .equip_name {
      display: inline-block;
      width: 100px;
    }
    h1 {
      padding: 26px 16px 0 16px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #353535;
      line-height: 22px;
    }
    ul {
      margin-top: 29px;
      li {
        display: flex;
        align-items: flex-start;
        font-size: 16px;
        font-family: NotoSansHans-Regular, NotoSansHans;
        font-weight: 400;
        color: #303235;
        margin-bottom: 39px;
        span {
          padding: 0 0 0 32px;
          display: inline-block;
          width: 100px;
          font-size: 14px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #353535;
        }
        p {
          flex: 1;
          padding: 0 32px 0 10px;
          font-size: 14px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #888888;
          line-height: 20px;
        }
        .reminder {
          color: #29bebc;
        }
      }
    }
  }
}
.close_page {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #fff;
}
</style>