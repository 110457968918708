import Vue from 'vue'
import VueRouter from 'vue-router'
import App from '../App.vue'
import home from '../home.vue'
import login from '../views/page/login.vue' //
import notPass from '../views/page/notPass.vue' //
import passAudit from '../views/page/passAudit.vue' //
import message from '../views/page/message.vue' //
Vue.use(VueRouter)

const routes = [{
        path: '/home',
        name: 'home',
        component: home,
        children: [{
            path: '/login',
            name: 'login',
            component: login
        }, {
            path: '/notPass',
            name: 'notPass',
            component: notPass
        }, {
            path: '/passAudit',
            name: 'passAudit',
            component: passAudit
        }, {
            path: '/message',
            name: 'message',
            component: message
        }]
    }


]

const router = new VueRouter({
    routes
})

export default router