<template>
  <div class="form_box">
    <van-form @submit="onSubmit">
      <span class="lable_type">身份证号</span>
      <van-field
        v-model="loginData.idNumber"
        name="身份证号"
        placeholder="请输入身份证号"
        :rules="[
          {
            required: true,
            pattern:
              /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/,
            message: '请输入正确身份证号',
          },
        ]"
      />
      <span class="lable_type set_pad_top">手机号</span>
      <van-field
        v-model="loginData.phone"
        name="手机号"
        placeholder="请输入手机号"
        :rules="[
          {
            required: true,
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: '请输入正确手机号',
          },
        ]"
      />
      <div
        class="remember_password"
        @click="rememberAccount = !rememberAccount"
      >
        <span :class="{ span_bgi: rememberAccount }"></span>
        记住账号
      </div>
      <van-button round block type="info" native-type="submit">提交</van-button>
    </van-form>
  </div>
</template>
<script>
import { getQRcode } from "@api/index.js";
export default {
  data() {
    return {
      loginData: {
        idNumber: "",
        phone: "",
        isLogin: 1,
      },
      rememberAccount: true,
    };
  },
  created() {
    let loginDataWx = JSON.parse(localStorage.getItem("LOGINDATAWX"));
    if (loginDataWx) {
      this.loginData = loginDataWx;
      this.onSubmit();
    }
  },
  mounted() {
    // localStorage.clear();
  },
  methods: {
    onSubmit() {
      const toast = this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      getQRcode(this.loginData).then((res) => {
        if (this.rememberAccount) {
          localStorage.setItem("LOGINDATAWX", JSON.stringify(this.loginData));
        }
        toast.clear();
        if (res.code == 200) {
          console.log(res);
          this.$router.push({
            path: "passAudit",
            query: {
              qrCode: res.data,
              rememberAccount: this.rememberAccount,
              idNumber: this.loginData.idNumber,
              phone: this.loginData.phone,
            },
          });
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.form_box {
  // padding-top: 40px;
  padding: 40px 27px 0 27px;
  .set_pad_top {
    padding-top: 54px;
    display: inline-block;
  }
  .remember_password {
    font-size: 14px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #353535;
    display: flex;
    align-items: center;
    padding-top: 19px;
    padding-bottom: 50px;
    span {
      display: inline-block;
      width: 14px;
      height: 14px;
      border: 1px solid #efeff4;
      margin-right: 8px;
    }
    .span_bgi {
      background-image: url("../../assets/img/radio-sel.png");
      border: none;
      background-size: 100% 100%;
    }
  }
}
.lable_type {
  font-size: 16px;
  font-family: NotoSansHans-Regular, NotoSansHans;
  font-weight: 400;
  color: #303235;
}
</style>
<style lang="scss">
.form_box {
  .van-cell {
    padding-left: 0px;
    padding-right: 0px;
  }
  .van-button--round {
    width: 343px;
    height: 44px;
    background: #29bebc;
    border-radius: 5px;
    border: none;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .van-cell::after {
    left: 0px;
    right: 0px;
  }
  .van-field--error .van-field__control, .van-field--error .van-field__control::placeholder{
    color: #999;
  }
}
</style>