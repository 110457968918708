<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  created() {
    this.$toast.setDefaultOptions({ duration: 3000 });
  },
  methods: {},
};
</script>
<style lang="scss">
@import url("./assets/icon-font/iconfont.css");
html,
body,
#app {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // -webkit-touch-callout: none;
  // -webkit-user-select: none;
  // -khtml-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
}
* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input,
textarea {
  -webkit-user-select: auto; /*webkit浏览器*/
}

li {
  list-style: none;
}
.view_box {
  height: calc(100% - 48px);
  overflow: auto;
}
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 48px;
  background: #ffffff;
  box-shadow: 0px -1px 0px 0px #e6eaf0;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #cacad2;
  }
  .fot_name {
    margin-top: 2px;
    font-size: 10px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 14px;
  }
  .set_font_color {
    color: #29bebc;
  }
}
.apostrophe {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.list_box .van-collapse-item__content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
// *:not(input,textarea) {
//    -webkit-touch-callout: none;
//    -webkit-user-select: none;
// }
</style>
