import axios from "axios";
// import Vue from 'vue'
const qs = require("qs");

function postSubmit(url, parameterType = "JSON") {
    return function(params = {}) {
        // params.unitCode = sessionStorage.getItem('unitCode')
        // params.hospitalCode = sessionStorage.getItem('hospitalCode')
        // params.platformFlag = '0'
        return axios
            .post(__PATH.BASEDPATH + url, parameterType == 'JSON' ? params : qs.stringify(params), {
                headers: {
                    'token': 'YvQe1LmwHoyFtwX3fMI+UzvFyunfblWrok1Ib/9JljxQ53/ztkhGiefjvPFwUM9hVnDW2Xh4/kTH4qXIFQohc3rZ6vAIct4YPDlR0CdQ+5z4BwVmXtPfB0E6prM4Tr68DrtOzrMa4IeUhLb1TXmazY+2xzwMTIJu48yU5MNlRQE=',
                }
            })
            .then(res => {
                console.log(res);
                return res.data;
            })
            .catch(error => {
                throw new Error(error);
            });
    };
}

function messageSubmit(url, parameterType = "JSON") {
    return function(params = {}) {
        return axios
            .post(__PATH.BASEDPATH + url, parameterType == 'JSON' ? params : qs.stringify(params), {})
            .then(res => {
                console.log(res);
                return res.data;
            })
            .catch(error => {
                throw new Error(error);
            });
    };
}
export {
    postSubmit,
    messageSubmit
}