var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form_box"},[_c('van-form',{on:{"submit":_vm.onSubmit}},[_c('span',{staticClass:"lable_type"},[_vm._v("身份证号")]),_c('van-field',{attrs:{"name":"身份证号","placeholder":"请输入身份证号","rules":[
        {
          required: true,
          pattern:
            /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/,
          message: '请输入正确身份证号',
        } ]},model:{value:(_vm.loginData.idNumber),callback:function ($$v) {_vm.$set(_vm.loginData, "idNumber", $$v)},expression:"loginData.idNumber"}}),_c('span',{staticClass:"lable_type set_pad_top"},[_vm._v("手机号")]),_c('van-field',{attrs:{"name":"手机号","placeholder":"请输入手机号","rules":[
        {
          required: true,
          pattern:
            /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
          message: '请输入正确手机号',
        } ]},model:{value:(_vm.loginData.phone),callback:function ($$v) {_vm.$set(_vm.loginData, "phone", $$v)},expression:"loginData.phone"}}),_c('div',{staticClass:"remember_password",on:{"click":function($event){_vm.rememberAccount = !_vm.rememberAccount}}},[_c('span',{class:{ span_bgi: _vm.rememberAccount }}),_vm._v(" 记住账号 ")]),_c('van-button',{attrs:{"round":"","block":"","type":"info","native-type":"submit"}},[_vm._v("提交")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }